//
// Name: Mixins
// Author: Graffino (http://www.graffino.com)
//

/**
 * Custom mixins
 */

// Convert to REM
rem($value) {
    return unit($value/$base-font-size-px, "rem");
}

// Convert to EM
em($value) {
    return unit($value/$base-font-size-px, "em");
}

// Font mixin
webfont($family, $file, $weight = normal, $style = normal) {
    @font-face {
        font-family: $family;
        src: url($url-fonts + $file + ".woff2") format("woff2"),
             url($url-fonts + $file + ".woff") format("woff"),
             url($url-fonts + $file + ".ttf") format("truetype"); // @stylint ignore
        font-weight: $weight;
        font-style: $style;
    }
}
