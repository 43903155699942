//
// Name: Forms
// Author: Graffino (http://www.graffino.com)
//

/**
 * Standard form
 */


input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"]
input[type="file"] {
    appearance: none;
}

input,
textarea {
    &::placeholder {
        font-family: $sans-font-family;
        font-size: rem(14);
        font-style: italic;
        color: $color-grey;
    }
}

.select2-selection__placeholder,
.input-placeholder {
    font-family: $sans-font-family;
    font-size: rem(14);
    font-style: italic;
    color: $color-grey !important;
}

.form {
    cf();
}

.inbound {
    &__form {
        background-image: url('../images/placeholders/request.jpg');
        background-color: $color-background-press;
        background-position: 50% 0;

        .title {
            margin: 1.4em 0;
            color: $color-button-hollow;
        }
        .request_form {
            .container {
                padding-bottom: 0;
                +below($tablet) {
                    padding: 3.625em 3.875em 3.5em;
                }
                +below($iphone6p) {
                    padding: 3.625em 1.875em 3.5em;
                }
            }
        }
        &__input {
            outline: 0;
        }
    }
}

.request_form {
    &--full {
        width: 100%;
        float: left;
        &:after {
            content: "";
            display: inline-block;
            width: 98%;
            height: 1px;
            margin-left: 1%;
            background-color: $color-background-press;
        }
        &.no-after {
            &:after {
                display: none;
            }
        }

        &.-margin-top {
            margin-top: rem(8);
        }
    }
    &--half {
        col(1/2);
        +below($tablet) {
            width: 100%;
        }

        span {
            float: left;
        }
    }
    .input__container {
        width: 100%;
        display: inline-block;
        margin-bottom: 0.875rem;

        &.-no-margin-bottom {
            margin-bottom: 0;
        }

        &--half {
            col(1/2, gutter: 7);
            +below($medium) {
                col(1, gutter: 0);
            }

            &.h-reverse {
                display: flex;
                flex-direction: column-reverse;
            }
        }
        &--full {
            width: 100%;
            &.textarea {
                width: 74%;
                margin: 0;
            }

            &.h-reverse {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }
    .button {
        float: left;
        margin: 1.6rem 0;
    }
    .mark-registerd {
        float: left;
        width: 100%;
        font-weight: 100;
        font-size: 0.7rem;
        color: $color-bright-grey;
    }
    input {
        display: block;
        width: 100%;
        padding: 5px;
        border: 1px solid $color-background-call;
        border-radius: 3px;
        outline: 0;
    }
    .textarea {
        margin: 1rem 0;
    }
    textarea {
        padding: rem(12) rem(10);
        width: 100%;
        min-height: 12.5rem;
        max-height: 12.6rem;
        border: 1px solid $color-background-call;
        border-radius: 3px;
    }
    label {
        display: block;
        margin: 0 0 0.5rem 0;
        font-size: 0.875rem;
        color: $color-menu-active;
    }
    .select-result {
        background-color: $color-background-job;
        color: $color-menu-active;
    }
    .select2-container {
        width: 100% !important;

        .select2-selection--single {
            padding: 2px 0;
            height: 34px;
            border: 1px solid $color-background-call;
            outline: 0;
        }
        &--default {
            .select2-selection--single .select2-selection__arrow {
                padding: 1rem;
                background-color: $color-bright-blue-alt;
                border-radius: 0 3px 3px 0;
            }
            &.select2-container--open .select2-dropdown {
                padding: 1rem;
                background-color: $color-background-job;
                color: $color-menu-active;
            }
            &.select2-container--open .select2-selection--single .select2-selection__arrow b {
                border-color: transparent transparent white transparent;
            }
            .select2-selection--single .select2-selection__arrow b {
                border-color: white transparent transparent transparent;
            }
            .select2-selection--single .select2-selection__rendered {
                width: 100%;
            }
            .selection {
                width: 100%;
            }
        }
        .select2-selection--single {
            width: 100%;
        }
    }
    .select2-search--dropdown { display: none; }
    .custom-file-input {
        color: transparent;
        padding-right: 0;
    }
    .day, .month {
        float: left;
        width: 20%;
        margin-right: 3%;
    }
    .year {
        width: 54%;
    }
    label {
    user-select: none;
    }

    input[type=checkbox].css-checkbox,
    input[type=radio].css-checkbox {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height:1px;
        width:1px;
        margin:-1px;
        padding:0;
        border:0;
    }

    input[type=checkbox].css-checkbox + label.css-label,
    input[type=radio].css-checkbox + label.css-label {
        padding-left: 20px;
        height: 20px;
        width: 22px;
        display: inline-block;
        line-height: 15px;
        background-repeat: no-repeat;
        background-position: 0 -2px;
        font-size: 15px;
        vertical-align: middle;
        cursor: pointer;
    }
    input[type=checkbox].css-checkbox:hover + label.css-label,
    input[type=radio].css-checkbox:hover + label.css-label {
        background-position: 0 -25px;
    }
    input[type=checkbox].css-checkbox:checked + label.css-label,
    input[type=radio].css-checkbox:checked + label.css-label {
        background-position: 0 -48px;
    }

    .css-label{
        background-image: url('../images/main/check-box.png');
    }
    .checkbox-area {
        width: 100%;
        display: inline-block;
        margin-bottom: 7px;

        &.-no-margin-bottom {
            margin-bottom: 0;
        }

        label {
            float: left;
        }
        .css-label {
            margin: rem(-3) rem(15) 0 rem(2);
        }
        &__title {
            margin-right: 15px;
        }
    }
    .small-gap {
        margin-top: 2.5rem;
    }
}
#datepicker:after {
    content: "";
    padding: 5px;
    background-color: red;
    display: inline-block;
}

.input-form,
textarea,
select,
input {
    color: $color-paragraph;

    &.ui-state-error {
        background-color: rgba(221, 82, 75, 0.3);
        border: 1px solid $color-button-filled;
    }

    &.ui-state-error ~ label {
        color: $color-button-filled;
    }

    &.ui-state-valid {
        background-color: $color-bg-valid;
        border: 1px solid $color-valid;
    }
    &.ui-state-valid ~ label {
        color: $color-valid;
    }

    &:focus {
        background-color: $color-background-press;
        border: 1px solid $color-grey;

        &.ui-state-error {
            background-color: rgba(221, 82, 75, 0.3);
            border: 1px solid $color-button-filled;
        }

        &.ui-state-error ~ label {
            color: $color-button-filled;
        }

        &.ui-state-valid {
            background-color: $color-bg-valid;
            border: 1px solid $color-valid;
        }

        &.ui-state-valid ~ label {
            color: $color-valid;
        }
    }
}

label[for$='upload'] {
    &::after {
        position: absolute;
        top: rem(30)
        right: 0;
        content: attr(data-placeholder);
        display: inline-block;
        background: $color-bright-blue-alt;
        border-radius: 0 3px 3px 0;
        padding: 8px 12px;
        outline: none;
        margin-top: -5px;
        user-select: none;
        cursor: pointer;
        font-size: 10pt;
        color: white;
    }
}

.file-upload {
    text-indent: -999px;
    height: 33px;
    line-height: 33px;
}

.file-upload__container {
    position: relative;

    .input-placeholder {
        margin-top: rem(-24);
        margin-left: rem(8);
    }
}

.-has-datepicker {
    position: relative;

    &::after {
        content: "";
        position: absolute ;
        right: 0;
        bottom: 0;
        height: 100%;
        width: rem(35);
        height: 32px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        background-color: $color-bright-blue-alt;
        background-image: url("../images/svg/calendar.svg");
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        pointer-events: none;
    }
}
.email_v {
    display: none !important;
}
.request_form .custom-file-input.ui-state-valid {
    color: $color-brand-black;
}
.request_form .custom-file-input.ui-state-valid + .input-placeholder {
    display: none;
}
