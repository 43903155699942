//
// Name: Buttons
// Author: Graffino (http://www.graffino.com)
//

/**
 * Standard button
 */
.button {
    background-color: transparent;
    border-radius: rem(4);
    outline: 0;
    border: 0;
    text-transform: uppercase;
    transition: all 0.2s;
    text-decoration: none;
    cursor: pointer;

    &.primary {
        padding: 0.86em 2.77em;
        font-size: 1em;
        font-weight: 600;

    }
    &.secondary {
        padding: 0.5rem 2.77rem;
        border: 2px solid $color-big-paragraph;
        color: $color-big-paragraph;

        &:hover {
            background-color: $color-big-paragraph;
            color: white;
        }
    }
    &.sml {
        padding: 10px;
        width: 11.25em;
        min-width: 11.25em;
    }
    &.center {
        text-align: center;
    }

    &.-size-wide {
        padding: rem(14) rem(40);
    }

    .icon {
        // Enable click trough icons
        pointer-events: none;
    }

    &__hollow {
        border: 2px solid $color-button-hollow;
        color: $color-button-hollow;
        &:hover {
            background: $color-button-hollow;
            color: white;
        }
    }

    &__filled {
        background: $color-button-filled;
        border: 2px solid $color-button-filled;
        color: white;
        &:hover {
            background: darken($color-button-filled, 20%);
            border: 2px solid darken($color-button-filled, 20%);
        }
    }
}
