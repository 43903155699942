//
// Name: Common
// Author: Graffino (http://www.graffino.com)
//

/**
 * Globals
 */

// Border-box

* {
    box-sizing: border-box;
    font-family: $sans-font-family;
}
// HTML

// Body
body {
    // Defaults
    overflow-x: hidden;
    min-width: rem(320);
    font-family: $default-font-family;
    font-size: 1em;
    line-height: 1.2;

    .read-more { // Link in body (read more, footer etc)
        display: block;
        float: left;
        width: 100%;
        text-align: right;
        a {
            color: $color-link-page;
            text-decoration: none;
            font-size: 0.938em;
        }
        +below($small) {
            margin-top: 1rem;
        }
    }
}

h2 {
    margin-top: 28px;
    margin-bottom: 28px;
}

.h-block-mobile {
    +below($small) {
        display: block;
    }
}

.-show-mobile {
    display: none;
    +below($small) {
        display: block;
    }
}

.-margin-top {
    margin-top: rem(10);
}

// Container
.container {
    cf();
    center(rem(1230));
    padding: rem(15);

    &.-no-padding-top {
        padding-top: 0;
    }
}

/**
 * Helpers
 */
.h {
    &-show-for {
        &-medium-up {
            +below($medium) {
                display: none !important;
            }
        }
        &-medium-down {
            +above($medium + 1) {
                display: none !important;
            }
        }
        &-tablet-up {
            +below($tablet) {
                display: none !important;
            }
        }
        &-tablet-down {
            +above($tablet + 1) {
                display: none !important;
            }
        }
        &-small-up {
            +below($small) {
                display: none !important;
            }
        }
        &-small-down {
            +above($small + 1) {
                display: none !important;
            }
        }
    }
    &-hide-for {
        &-tablet-up {
            +above($tablet +1) {
                display: none !important;
            }
        }
        &-tablet-down {
            +below($tablet) {
                display: none !important;
            }
        }
        &-small-up {
            +above($small +1) {
                display: none !important;
            }
        }
        &-small-down {
            +below($small) {
                display: none !important;
            }
        }
        &-xsmall-up {
            +above($xsmall +1) {
                display: none !important;
            }
        }
        &-xsmall-down {
            +below($xsmall) {
                display: none !important;
            }
        }
    }
    &-vertical-align-middle {
        vertical-align: middle;
    }
}
.clearclr {
    clear: none !important;
}

/**
 * Wordpress
 */
img.alignright {
    float: right;
    margin: 0 0 rem(10) rem(50);

    +below($medium) {
        float: none;
        margin: 0;
        width: 100%;
        height: auto;
    }

}
img.alignleft {
    float: left;
    margin: 0 rem(50) rem(10) 0;

    +below($medium) {
        float: none;
        margin: 0;
        width: 100%;
        height: auto;
    }
}

img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;

}
.alignright {
    float: right;
}
.alignleft {
    float: left;
}
.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
