//
// Name: SVG Sprites
// Author: Graffino (http://www.graffino.com)
//

/**
 * Mustache template
 */
// @stylint off
sprite() {
    background-image: url("../images/sprite.svg");
    background-size: rem(286) rem(189);
    background-repeat: no-repeat;
}
svg-arrow-left() { 
    sprite() 
    background-position: rem(-136) rem(-105);
}
svg-arrow-left-dims() { 
    width: rem(16);
    height: rem(22);
}

svg-arrow-right() { 
    sprite() 
    background-position: rem(-152) rem(-105);
}
svg-arrow-right-dims() { 
    width: rem(16);
    height: rem(22);
}

svg-calendar() { 
    sprite() 
    background-position: rem(0) rem(-171);
}
svg-calendar-dims() { 
    width: rem(18);
    height: rem(18);
}

svg-call-center() { 
    sprite() 
    background-position: rem(-196) rem(0);
}
svg-call-center-dims() { 
    width: rem(90);
    height: rem(75);
}

svg-data-acquisition() { 
    sprite() 
    background-position: rem(-196) rem(-75);
}
svg-data-acquisition-dims() { 
    width: rem(40);
    height: rem(48);
}

svg-details() { 
    sprite() 
    background-position: rem(-154) rem(-150);
}
svg-details-dims() { 
    width: rem(21);
    height: rem(21);
}

svg-details-gray() { 
    sprite() 
    background-position: rem(-133) rem(-150);
}
svg-details-gray-dims() { 
    width: rem(21);
    height: rem(21);
}

svg-facebook() { 
    sprite() 
    background-position: rem(-196) rem(-123);
}
svg-facebook-dims() { 
    width: rem(33);
    height: rem(33);
}

svg-fax() { 
    sprite() 
    background-position: rem(-91) rem(-105);
}
svg-fax-dims() { 
    width: rem(23);
    height: rem(22);
}

svg-file() { 
    sprite() 
    background-position: rem(0) rem(0);
}
svg-file-dims() { 
    width: rem(105);
    height: rem(105);
}

svg-job-type() { 
    sprite() 
    background-position: rem(-168) rem(-105);
}
svg-job-type-dims() { 
    width: rem(22);
    height: rem(22);
}

svg-job-type-gray() { 
    sprite() 
    background-position: rem(-112) rem(-150);
}
svg-job-type-gray-dims() { 
    width: rem(21);
    height: rem(21);
}

svg-jobs() { 
    sprite() 
    background-position: rem(0) rem(-105);
}
svg-jobs-dims() { 
    width: rem(91);
    height: rem(66);
}

svg-languages() { 
    sprite() 
    background-position: rem(-169) rem(-127);
}
svg-languages-dims() { 
    width: rem(18);
    height: rem(17);
}

svg-languages-gray() { 
    sprite() 
    background-position: rem(-175) rem(-150);
}
svg-languages-gray-dims() { 
    width: rem(21);
    height: rem(21);
}

svg-location() { 
    sprite() 
    background-position: rem(-91) rem(-127);
}
svg-location-dims() { 
    width: rem(18);
    height: rem(23);
}

svg-location-gray() { 
    sprite() 
    background-position: rem(-91) rem(-150);
}
svg-location-gray-dims() { 
    width: rem(21);
    height: rem(21);
}

svg-mail() { 
    sprite() 
    background-position: rem(-148) rem(-127);
}
svg-mail-dims() { 
    width: rem(21);
    height: rem(18);
}

svg-mobile() { 
    sprite() 
    background-position: rem(-109) rem(-127);
}
svg-mobile-dims() { 
    width: rem(17);
    height: rem(23);
}

svg-open-position() { 
    sprite() 
    background-position: rem(-126) rem(-127);
}
svg-open-position-dims() { 
    width: rem(22);
    height: rem(23);
}

svg-open-position-gray() { 
    sprite() 
    background-position: rem(-105) rem(-76);
}
svg-open-position-gray-dims() { 
    width: rem(33);
    height: rem(29);
}

svg-phone() { 
    sprite() 
    background-position: rem(-114) rem(-105);
}
svg-phone-dims() { 
    width: rem(22);
    height: rem(22);
}

svg-printer() { 
    sprite() 
    background-position: rem(-236) rem(-75);
}
svg-printer-dims() { 
    width: rem(46);
    height: rem(45);
}

svg-responsibilities() { 
    sprite() 
    background-position: rem(-262) rem(-123);
}
svg-responsibilities-dims() { 
    width: rem(24);
    height: rem(24);
}

svg-skills() { 
    sprite() 
    background-position: rem(-138) rem(-76);
}
svg-skills-dims() { 
    width: rem(26);
    height: rem(25);
}

svg-support() { 
    sprite() 
    background-position: rem(-105) rem(0);
}
svg-support-dims() { 
    width: rem(91);
    height: rem(76);
}

svg-touch-icon() { 
    sprite() 
    background-position: rem(-164) rem(-76);
}
svg-touch-icon-dims() { 
    width: rem(20);
    height: rem(23);
}

svg-twitter() { 
    sprite() 
    background-position: rem(-229) rem(-123);
}
svg-twitter-dims() { 
    width: rem(33);
    height: rem(33);
}

// @stylint on
