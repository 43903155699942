//
// Name: Plugins
// Author: Graffino (http://www.graffino.com)
//

/**
 * Magnific Popup Plugin
 */

// Overlay at start
.mfp-fade.mfp-bg {
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.mfp-bg {
    opacity: 0.97;
}
// Overlay animate in
.mfp-fade.mfp-bg.mfp-ready { opacity: 0.8; }
// Overlay animate out
.mfp-fade.mfp-bg.mfp-removing { opacity: 0; }
// Content at start
.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
// Content animate in
.mfp-fade.mfp-wrap.mfp-ready .mfp-content { opacity: 1; }
// Content animate out
.mfp-fade.mfp-wrap.mfp-removing .mfp-content { opacity: 0; }


.mfp-figure {
    position: relative;
}

// Custom close button
.mfp-image-holder .mfp-close {
    position: absolute;
    bottom: 0;
    color: $color-big-paragraph;
    font-size: rem(12);

    &::after {
        content: "Close";
        display: inline-block;
        vertical-align: bottom;
        margin-left: rem(2);
        font-size: rem(10);
        color: $color-big-paragraph;
        text-transform: none;
    }
}

/**
 * Select2 Plugin
 */

.select2-search__field {
    display: none;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $color-bright-blue-alt;
}

.select2-results__option {
    font-family: $sans-font-family;
    font-size: rem(14);
    font-weight: 400;
    color: $color-menu-active;
    background-color: $color-background-job;
}

.select2-search--dropdown {
    padding: 0;
}

.select2-dropdown {
    border-color: $color-background-job;
}

.select2-results__option[aria-disabled="true"] {
    display: none;
}

.select2-selection__rendered {
    font-family: $sans-font-family;
    font-size: 16px;
    font-weight: 400;
    color: $color-button-hollow !important;
}

/**
 * Slick plugin
 */
.slick-dots {
    bottom: rem(15);

    +below($tablet) {
        bottom: 0;
    }

    li {
        width: rem(6);
        height: rem(6);

        button {
            display: inline-block;
            width: rem(6);
            height: rem(6);
            background-color: $color-link-page;
            border-radius: 50%;

            &::before {
                content: none;
            }
        }

        &.slick-active {
            button {
                background-color: $color-background-news;
            }
        }
    }
}

.slick-prev,
.slick-next {
    z-index: 5;

    &::before {
        content: "";
    }
}

.slick-prev {
    left: rem(15);

    &:hover {
        @extends .icon__arrow-left;
    }
}

.slick-next {
    right: rem(15);

    &:hover {
        @extends .icon__arrow-right;
    }
}

/**
 * Pickadate plugin
 */

.picker {
    margin-top: rem(4);
    visibility: hidden;
    width: 228px;

    +below($small) {
        width: 300px;
    }
}

.picker--opened {
    visibility: visible;
}

.picker__holder {
    overflow: visible;
    outline: 0;
    border: 0;
    width: 100%;
}

.picker__header {
    margin-top: 0;
    border-radius: rem(3) rem(3) 0 0;
    padding: rem(6) 0;
    background-color: $color-bright-blue-alt;
}

.picker__month,
.picker__year {
    color: white;
    font-family: $sans-font-family;
    font-size: rem(12);
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
}

.picker__day--today {
    &::before {
        content: none;
    }
}

.picker__table {
    margin: 0;

    thead {
        tr {
            background-color: $color-bright-blue;
        }
        th {
            opacity: 0.7;
            padding: rem(8) 0;
            font-family: $sans-font-family;
            font-size: rem(11);
            font-weight: 400;
            color: white;
            text-transform: uppercase;
        }
    }

    tbody {
        background-color: $color-bright;

        td {
            border: 1px solid $color-background-news;
            font-family: $sans-font-family;
            font-size: rem(12);
            font-weight: 400;
            color: $color-bright-blue;
            text-transform: uppercase;
        }
    }
}

.picker__day--highlighted:hover, .picker--focused .picker__day--highlighted {
    border-color: $color-ul-menu;
    color: inherit;
    background-color: inherit;
}

.picker__footer {
    display: none;
}

.picker__box {
    padding: 0;
}

.picker__nav--prev,
.picker__nav--next {
    top: 0;

    &:hover {
        background-color: transparent;
    }
}

.picker__nav--prev::before,
.picker__nav--next::before {
    border-bottom-width: rem(6);
    border-top-width: rem(6);
}

.picker__nav--prev::before {
    border-right-color: white;
    border-right-width: rem(6);
}

.picker__nav--next::before {
    border-left-color: white;
    border-left-width: rem(6);
}

.picker__day--outfocus {
    color: $color-bright-blue;
}


// Datepicker
.ui-datepicker {
    font-family: $sans-font-family;
    &.ui-widget.ui-widget-content {
        padding: 0;
        border: 0;
    }
    .ui-datepicker-header {
        border-radius: 3px 3px 0 0;
    }
    .ui-widget-header {
        background-color: $color-bright-blue-alt;
        background-image: none;
        border: 0;
    }
    td {
        padding: 0 !important;
    }
    &-calendar thead {
        font-size: 11px;
        font-weight: 100;
        background-color: $color-bright-blue-alt;
        color: white;
    }
    &-calendar {
        border-collapse: separate !important;
        border-radius: 0 0 3px 3px;
        width: 205px;
        tr {
            border-bottom: 1px solid $color-background-inbound;
            border-radius: 0 0 3px 3px;
            td {
                a {
                    color: $color-bright-blue-alt;
                }
                border-left: 1px solid $color-background-inbound;
                border-bottom: 1px solid $color-background-inbound;
                &:last-child {
                    border-right: 1px solid $color-background-inbound;
                }
            }
            &:last-child {
                td:first-child { border-radius: 0 0 0 3px }
                td:last-child { border-radius: 0 0 3px 0 }
            }
        }
        tbody {
            border-left: 1px solid $color-background-inbound;
            border-bottom: 1px solid $color-background-inbound;
            border-right: 1px solid $color-background-inbound;
        }
    }
    &-title {
        font-weight: 400;
        color: white;
    }
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    background-color: $color-background-press;
    background-image: none;
    border: 0;
    color: $color-ul-menu;
    width: 100%;
    display: inline-block;
    text-align: center;
    text-decoration: none;
}
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    background-color: $color-background-press;
    background-image: none;
    color: $color-ul-menu;
}
.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
    opacity: 1;
    span {
        color: $color-background-inbound;
    }
}
.ui-widget-header .ui-icon {
    text-indent: 0;
    background-image: none;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widge-header .ui-state-hover, {
    background: none !important;
    border: 0;
}
