//
// Name: Navigation
// Author: Graffino (http://www.graffino.com)
//
//
.home {
    .header { background-color: transparent; }
}

.header {
    background-color: $color-brand-white;
    transition: margin 300ms ease-in-out;
    top: rem(-300);

    .container {
        +below($medium) {
            padding: 0 0 0 2em !important;
        }
    }

    .logo {
        col(3/15);
        margin-top: 1rem;
        margin-bottom: 0.6rem;

        img {
            max-width: 67%;

            +below($tablet) {
                max-width: 100%;
            }
        }

        +below($tablet) {
            col(4/15);
        }
    }

    &__nav {
        col(12/15);
        position: relative;
        float: right;
        +below($tablet) {
            col(11/15);
            padding-top: 0;
        }
        .language {
            +below($medium) {
                width: rem(76);
                position: absolute;
                right: 90px;
                float: right;
                margin: 0;
                margin-top: 1.2em;
                padding: 0;
                margin-right: 0.5rem;

                img {
                    width: rem(42);
                }
            }

            li {
                margin: 0 0.37em;

                &:last-child {
                    border-radius: 0 0 3px 3px;
                }
                +below($medium) {
                    display: none;
                    margin: 0;
                    padding: 10px 15px;
                    margin-top: -1px;
                    background-color: $color-brand-white;
                    border: 1px solid $color-bright;
                }
            }

            li.is-active {
                border: 0;
                display: block !important;
                &.is-open {
                    margin-bottom: 0;
                    border: 1px solid $color-bright;
                    background-color: $color-bright;
                }
                +below($medium) {
                    display: block;
                    cursor: pointer;
                    border-radius: 3px 3px 0 0;

                    &:after {
                        content: "";
                        display: inline-block;
                        width: 0;
                        height: 0;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        border-top: 4px solid $color-nav-font;
                        float: right;
                        position: absolute;
                        top: 0.5em;
                        right: -16px;
                        +below($desktop) {
                            top: rem(21);
                            border-left-width: 6px;
                            border-right-width: 6px;
                            border-top-width: 6px;
                        }

                        +below($tablet) {
                            right: 0;
                        }
                    }
                }
            }
            &__flag {
                opacity: 0.3;
                border: 1px solid $color-border-flag;
                transition: opacity 200ms ease-in-out;
                &:hover {
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }
        &--menu {
            margin-top: 0;
            margin-right: rem(-8);

            +below($medium) {
                position: absolute;
                right: 0;
                top: 83px;
                margin: 0;
                padding: 1.5em 0;
                background-color: $color-ul-menu;
                transition: margin 500ms ease-in-out;
            }

            +below($small) {
                position: fixed;
            }

            li {
                position: relative;
                white-space: nowrap;
                padding-bottom: rem(10);

                a {
                    transition: all 100ms ease-in-out;
                }
                &:hover a {
                    background-color: $color-menu-active;
                    color: $color-brand-white;
                    +below($medium) {
                        color: $color-brand-white;
                        background-color: transparent;
                    }
                }
                &:hover {
                    +below($medium) {
                        background-color: $color-menu-active;
                    }
                }

                .active {
                    +above($tablet) {
                        background-color: $color-menu-active;
                        color: $color-brand-white;
                    }
                }
                a {
                    padding: 0.8em rem(10);
                    color: $color-nav-font;
                    border-radius: 4px;
                    font-size: rem(12);
                    font-weight: 600;
                    text-transform: uppercase;
                    text-decoration: none;
                    +below($large) {
                        font-size: 13px;
                    }
                }

                ul {
                    li {
                        a {
                            background: none !important;
                        }
                    }
                }
            }
        }
        ul {
            float: right;
            z-index: 100;
            li {
                list-style-type: none;
                display: inline-block;
                float: left;
                +below($medium) {
                    padding: 0.5em 1em;
                    text-align: center;
                }
                &:hover ul {
                    max-height: 999px;
                    transition: max-height 500ms ease-in;
                }

                ul {
                    position: absolute;
                    overflow: hidden;
                    max-height: 0;
                    width: 8.750em;
                    float: none;
                    padding: 0;
                    margin-top: 0.5em;
                    background-color: $color-ul-menu;
                    transition: none;


                    +below($medium) {
                        top: 0;
                        margin: 0;
                        right: 162px;
                    }
                    li {
                        position: relative;
                        display: block;
                        float: none;
                        margin: 0;
                        padding: 10px 6px;
                        font-size: $p-small-font-size;
                        color: $color-brand-white;
                        transition: padding 200ms ease-in-out;
                        cursor: pointer;

                        &:after {
                            position: absolute;
                            bottom: 0;
                            left: 7%;
                            content: "";
                            display: inline-block;
                            width: 85%;
                            height: 2px;
                            background-color: $color-bright-blue;
                        }


                        &:hover {
                            padding-left: rem(10);
                        }

                        &:last-child {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
                +below($medium) {
                    display: block;
                    float: none;
                }
            }
        }
        .hamburger {
            position: absolute;
            right: 0;
            display: none;
            padding: 2.6em;
            background-image: url('../images/main/hamburger.png');
            background-repeat: no-repeat;
            background-position: 50%;
            z-index: 1000;
            cursor: pointer;
            &_open {
                background-color: $color-menu-active;
            }
            +below($medium) {
                display: inline-block;
                float: right;
            }
        }

    }
}


// Class for scrollTop
.header.is-fixed {
    position: fixed;
    top: rem(-300);
    width: 100%;
    max-height: none;
    min-height: 6.313em;
    background-color: $color-brand-white;
    z-index: 1001;

    +below($medium) {
        max-height: none;
        min-height: 83px;
    }

    &.is-up {
        margin-top: rem(-300);
    }

    &.is-down {
        top: 0;
        margin-top: 0;
    }

    .logo {
        margin-top: 1em;
        img {
            max-width: 46%;
            +below($tablet) {
                max-width: 65%;
            }
        }
    }

    .language {
        width: rem(76);
        position: absolute;
        right: 0;
        float: left;
        margin: 0;
        margin-top: 0;
        padding: 0;
        margin-right: 0.5rem;

        +below($medium) {
            margin-top: 1.1rem;
            right: 5rem;
        }

        +below($desktop) {
            margin-left: 0;
            margin-right: 0;
            padding: 0;
            margin-right: 0.5rem;
        }

        li {
            &:last-child {
                margin: 0 ;
            }

            display: none;
            margin-left: 0;
        }

        &.is-open {
            li {
                display: block;
                width: 100%;
            }
        }

        li.is-active {
            position: relative;
            display: block;
            cursor: pointer;
            margin-bottom: 0.8em;

            +below($medium) {
                margin-bottom: 0;
            }

            &:after {
                content: "";
                display: inline-block;
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid $color-nav-font;
                float: right;
                position: absolute;
                top: rem(7);
                right: -16px;

                +below($desktop) {
                    right: 0;
                }

                +below($medium) {
                    top: rem(21);
                }
            }
        }
    }
    .header__nav {
        padding-top: 2rem;
        +below($medium) {
            padding-top: 0;
        }
        &--menu {
            margin-right: 5rem;
        }
    }
}
.is-open.is-active:after {
    display: none !important;
}

.is-active {
    .language__flag {
        opacity: 1;
    }
}

.language.is-open {
    li {
        display: block;
    }
}


.header.is-fixed .language li.is-active {
    &::after {
        content: "";

        +below($medium) {
            top: rem(21);
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid $color-nav-font;
        }
    }
}

.header.is-fixed .language.is-open li.is-active {
    margin-bottom: 0;

    &::after {
        content: none;
    }
}


.header.is-fixed .language.is-open li.is-active {
    +below($medium) {
        border: 1px solid rgba(182, 191, 200, 0.3);
    }
}

.header__nav--menu {
    +below($medium) {
        margin-right: rem(-500) !important;

        &.is-open {
            margin-right: 0 !important;
        }
    }
}

.header.is-up {
    .header__nav--menu.is-open {
        +below($medium) {
            display: none;
        }
    }
}
