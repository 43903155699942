//
// Name: Hero
// Author: Graffino (http://www.graffino.com)
//

/**
 * Main hero
 */
.home {
    background-image: url('../images/backgrounds/hero.jpg');
    background-repeat: no-repeat;
    background-color: $color-background-hero;
    background-size: contain;
}
.hero {
    h1 {
        font-size: $h-hero-font-size;
        line-height: $h-hero-line-height;
        letter-spacing: -4px;
        color: $color-menu-active;
        +below($xlarge) {
            font-size: 4em;
        }
        +below($large) {
            font-size: 3em;
        }
        +below($medium) {
            margin-top: 0;
        }
    }
    &__section {
        .container {
            padding-top: 7.813em;
            padding-bottom: 7.813em;
            background-repeat: no-repeat;
            background-position: right top;
            +below($large) {
                background-size: 70%;
            }
            +below($medium) {
                text-align: center;
                padding-top: 0.813em;
                padding-bottom: 2em;
                background-position: 50% 100px;
                background-size: cover;
            }
            .button.primary {
                +below($medium) {
                    margin-top: 20em;
                }
            }
        }
    }
    .features {
        cf();
        box-shadow: 0 0 1px 0 rgba(27, 102, 164, 0.15);
    }
    .feature {
        col(0.333, gutter: 0);
        position: relative;
        min-height: 33em;
        padding: 3.75em 3.625em 3em;
        text-align: center;

        +below($medium) {
            width: 100%;
        }
        +below($large) {
            padding: 3.75em 2.625em 3em;
        }
        &__1 {
            background: $color-background-job;
        }
        &__2 {
            background: $color-background-call;
        }
        &__3 {
            background: $color-background-support;
        }
        &__title {
            margin: 12px 0;
            font-size: 1.5em;
            font-weight: 600;
            color: $color-menu-active;
        }
        p {
            font-size: 1rem;
            color: $color-paragraph;
            margin-bottom: 1.5rem;
            +below($medium) {
                font-size: 1.5rem;
            }
            +below($small) {
                font-size: 1rem;
            }
        }
        .button {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 2.8em;
            margin: 0 auto;

            +below($medium) {
                bottom: 1.8em;
            }
        }
    }
    h1 {
        font-family: $sans-font-family;
        font-weight: 100;
    }
}
.news {
    background-color: $color-background-news;
    padding: 4.25rem 0;
    .container {
        +below($medium) {
            padding: 0.8em;
        }
    }
    +below($xsmall) {
        padding: 0;
    }
    &__title {
        position: absolute;
        width: 100%;
        padding: 1em 2.375em 1.2em 1.375em;
        margin: 0;
        font-size: 2em;
        font-weight: 100;
        background-image: url('../images/backgrounds/news.png');
        background-repeat: no-repeat;
        background-position: left top;
        background-size: contain;
        color: $color-brand-white;
        z-index: 200;
    }
    &__section {
        col(0.5, gutter: 2);
        position: relative;
        min-height: 41rem;
        background: $color-brand-white;
        +below($medium) {
            width: 100%;
            margin: 0;
        }
        &.press {
            +below($small) {
                padding-bottom: 5rem;
            }
        }
        +below($small) {
            min-height: auto;
            margin-bottom: 1.2rem;
        }
        &.milestone {
            position: relative;
            .press__post {
                float: left;
                width: 98%;
                margin-top: 2.5em;
                padding: 0 2em 1.3rem 2em;
                border: 0;
                +below($medium) {
                    padding: 1em;
                }
                +below($small) {
                    text-align: center;
                    margin-top: 0;
                }
            }
            .icon {
                position: absolute;
                float: left;
                top: auto;
                bottom: rem(100);
            }
            .slider {
                &__images {

                    img {
                        width: 100%;
                    }
                }
                &__post {
                    cf();
                    position: relative;
                    padding: 0 1em;
                    &--navi {
                        cf();
                        width: 100%;
                        float: left;
                        text-align: center;
                        span {
                            display: inline-block;
                            padding: 5px;
                            background-color: $color-link-page;
                            border-radius: 50%;
                            &:first-child {
                                background-color: $color-background-inbound;
                            }
                        }
                    }
                    .press__post--text { margin: 0.75em 0 1.5em 0; }
                }
            }
        }
        .press {
            &__posts {
                margin-top: 6.250em;
                padding: 2em 3em;
                +below($large) {
                    padding: 1em 1em 2em 1em;
                }
                +below($small) {
                    text-align: center;
                }
                .press__post {
                    &:last-child { border: 0; }
                }
            }
            &__post {
                border-bottom: 1px solid $color-background-news;
                cf();
                padding: 0.4em 0 0.5em 0;

                &--title {
                    col(0.7, gutter: 0);
                    font-size: 0.938em;
                    font-weight: 600;
                    margin-bottom: 0;
                    +below($small) {
                        col(1);
                    }
                    a {
                        color: $color-link-page;
                        text-decoration: none;
                    }
                }
                &--date {
                    col(0.3, gutter: 0);
                    font-size: $p-small-font-size;
                    font-weight: 700;
                    text-align: right;
                    color: $color-page-title;
                    text-transform: uppercase;
                    +below($small) {
                        col(1);
                    }
                }
                &--text {
                    float: left;
                    margin: 0.75em 0 0 0;
                    font-size: 0.938em;
                    font-weight: 400;
                    color: $color-grey-alt;
                    p {
                        margin: 0;
                    }
                    +below($small) {
                        margin-top: 0;
                    }
                }

            }
        }
        .button {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 3.75em;
            margin: 0 auto;
            font-weight: 600;
        }
    }
}

.request {
    min-height: 16.875em;
    text-align: center;
    background-image: url('../images/backgrounds/quote.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $color-brand-white;
    p {
        font-size: 1.125em;
        line-height: 1.5em;
        color: $color-big-paragraph;
        margin: 2.5em auto;
        +below($small) {
            font-size: 1rem;
            padding: 0 5rem;
        }
        +below($xsmall) {
            padding: 0 2rem;
        }
    }
    .button {
        min-width: 13.75em;
        font-weight: 600;
    }
}

.footer {
    cf();
    padding: 2.5rem 0 0 0;
    background-color: $color-brand-white;
    .link {
        color: $color-link-page;
    }
    .logo {
        &__img {
            max-width: 67%;
            display: block;
            +below($medium) {
                max-width: 40%;
                margin: 0 auto;
            }

            +below($xsmall) {
                max-width: 50%;
            }
        }
    }
    &__bold {
        display: inline-block;
        font-size: $p-small-font-size;
        font-weight: 700;
        color: $color-footer-paragraph;
        text-transform: uppercase;
        margin: 0 0.9em 0 0;
    }
    .social__icons {
        margin-top: 60px;

        +below($medium) {
            margin-top: 20px;
        }
    }
    &__subtitle {
        margin-bottom: 1.875em;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        color: $color-footer-paragraph;
    }
    &__section {
        col(0.25);
        padding: 0 1.375em;
        a {
            font-size: 14px;
            color: $color-footer-paragraph;
            text-decoration: none;
        }
        ul {
            padding: 0;
            li {
                list-style-type: none;
            }
        }
        p {
            display: block;
            color: $color-footer-paragraph;
            font-size: 14px;
            line-height: 20px;

            a {
                font-weight: 700;
            }
        }
        +below($medium) {
            width: 100%;
            margin: 10px 0;
            text-align: center;
        }
    }
    &__apply {
        padding: 0 1rem;
    }
    &__offices {
        padding: 0 5.2em;
    }
    .copyright {
        margin-top: 2.5rem;
        font-size: 14px;
        text-align: center;
        color: $color-footer-paragraph;
    }
}
