//
// Name: SVG Icons
// Author: Graffino (http://www.graffino.com)
//

/**
 * All icons
 */
.icon {
    display: inline-block;
    vertical-align: middle;

    // File icons
    &__file {
        svg-icon_file();
        svg-icon_file-dims();

        &-color {

        }
    }
    &__jobs {
        svg-jobs();
        svg-jobs-dims();
    }
    &__call {
        svg-call-center();
        svg-call-center-dims();
    }
    &__support {
        svg-support();
        svg-support-dims();
    }
    &__facebook {
        svg-facebook();
        svg-facebook-dims();
    }
    &__twitter {
        svg-twitter();
        svg-twitter-dims();
    }
    &__arrow-left {
        svg-arrow-left();
        svg-arrow-left-dims();
    }
    &__arrow-right {
        svg-arrow-right();
        svg-arrow-right-dims();
    }
    &__mobile {
        svg-mobile();
        svg-mobile-dims();
        margin-right: 5px;
    }
    &__phone {
        svg-phone();
        svg-phone-dims();
    }
    &__fax {
        svg-fax();
        svg-fax-dims();
        margin-right: 4px;
    }
    &__mail {
        svg-mail();
        svg-mail-dims();
        margin-right: 10px;
    }
    &__data {
        svg-data-acquisition();
        svg-data-acquisition-dims();
    }
    &__email-fax {
        svg-printer();
        svg-printer-dims();
    }
    &__job-type {
        svg-job-type();
        svg-job-type-dims();
    }
    &__open-position {
        svg-open-position();
        svg-open-position-dims();
    }
    &__location {
        svg-location();
        svg-location-dims();
    }
    &__details {
        svg-details();
        svg-details-dims();
    }
    &__skills {
        svg-skills();
        svg-skills-dims();
    }
    &__responsibilities {
        svg-responsibilities();
        svg-responsibilities-dims();
    }
    &__job-type-gray {
        svg-job-type-gray();
        svg-job-type-gray-dims();
    }
    &__languages-gray {
        svg-languages-gray();
        svg-languages-gray-dims();
    }
    &__location-gray {
        svg-location-gray();
        svg-location-gray-dims();
    }
    &__details-gray {
        svg-details-gray();
        svg-details-gray-dims();
    }
    &__open-position-gray {
        svg-position-gray();
        svg-position-gray-dims();
    }
}
