//
// Name: Sections
// Author: Graffino (http://www.graffino.com)
//

/**
 * Sections modules
 */
.no-padding {
    .section {
        +below($small) {
            padding: 0;
        }
    }
}
.page {
    background-repeat: no-repeat;
    background-color: $color-background-press;
    background-size: contain;
    +below($medium) {
        background-position: 0 2%;
    }
    +below($iphone6p) {
        background-position: 0 100px;
        background-size: 500px;
    }
    .header {
        min-height: 7.5em;
        background-color: $color-brand-white;

        +below($medium) {
            min-height: 6em;
        }
    }
    &__title {
        margin: 4rem 0;
        font-size: 6rem;
        font-weight: 200;
        text-align: center;
        color: $color-brand-white;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);

        +below($xsmall) {
            margin: 1rem 0;
        }
    }
    &__subtitle {
        margin: 2.2rem 0 0 0;
        font-size: 2.2em;
        color: $color-brand-white;
        word-break: break-all;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);

    }
    &__date {
        color: $color-page-title;
        font-size: 1.125em;
        margin: 2rem 0 2rem 0;
    }
    &__post {
        col(4/12, gutter: 1.5, cycle: 3);
        min-height: em(460);
        margin-top: em(50);
        padding: em(50) em(50) em(50) em(38);

        background-color: $color-brand-white;
        box-shadow: 0 0 1px 0 rgba(27, 102, 164, 0.15);

        &:nth-child(-n+3) {
            margin-top: 0;
        }

        +below($desktop) {
            width: 100%;
            margin-bottom: 1rem;
            min-height: auto;
        }
        &--title {
            font-size: 1.25em;
            font-weight: 600;
            color: $color-page-post-title;

            a {
                color: $color-page-post-title;
                text-decoration: none;
            }
        }
        &--date {
            font-size: $p-small-font-size;
            font-weight: 600;
            color: $color-footer-paragraph;
            text-transform: uppercase;
        }
        &--text {
            font-size: 1em;
            font-weight: 300;
            line-height: 1.4;
            color: $color-grey-alt;
        }
        img {
            width: 300px;
            height: auto;
            margin-top: 14px;

            +below($iphone6p) {
                width: auto;
                max-width: 100%;
                height: auto;
            }
        }
        .read-more {
            a {
                float: left;
                margin-top: 0.5em;
            }
        }
    }
    .post {
        &__navigation {
            cf();
            width: 33em;
            margin: 0 auto;
            padding: 1.5em;
            +below($small) {
                width: 100%;
            }
            .previous__text, .next__text {
                font-size: 0.938em;
                color: $color-link-page;
            }
            &--content {
                display: inline-block;
                float: left;
                width: 15em;
                padding: 0.8em 1.563em;
                background-color: $color-brand-white;
                border: 1px solid $color-background-job;
                a {
                    text-decoration: none;
                }
                +below($small) {
                    width: 50%;
                }
                &:first-child {
                    border-radius: 3px 0 0 3px;
                }
                &:last-child {
                    border-radius: 0 3px 3px 0;
                    border-left: 0;
                }
                .nav__post {
                    p {
                        position: relative;
                        margin: 0.4em 0;
                    }
                    .previous__text {
                        cursor: pointer;
                        transition: color 200ms ease-in-out;

                        &:hover {
                            color: $color-footer-paragraph;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            top: 5px;
                            left: -8px;
                            display: inline-block;
                            float: left;
                            width: 0;
                            height: 0;
                            border-right: 5px solid $color-bright-blue-alt;
                            border-left: 0 solid transparent;
                            border-top: 4px solid transparent;
                            border-bottom: 4px solid transparent;
                        }
                    }
                    .next__text {
                        cursor: pointer;
                        transition: color 200ms ease-in-out;

                        &:hover {
                            color: $color-footer-paragraph;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            top: 5px;
                            right: -15px;
                            display: inline-block;
                            float: left;
                            width: 0;
                            height: 0;
                            border-right: 5px solid transparent;
                            border-left: 6px solid $color-bright-blue-alt;
                            border-top: 4px solid transparent;
                            border-bottom: 4px solid transparent;
                        }
                    }
                }
                .prev__post {
                    p { text-align: left; }
                }
                .next__post {
                    .previous__text {}
                    p { text-align: right }
                }
                .post__title {
                    cursor: pointer;
                    font-size: 0.938em;
                    color: $color-grey;
                    transition: color 200ms ease-in-out;
                    &:hover {
                        color: $color-link-page;
                    }
                }
            }
        }
    }
    .request {
        min-height: 10em;
        background-color: $color-background-press;
        h2 {
            margin-top: 1em;
            font-size: 1.5em;
            font-weight: 400;
            text-align: center;
            color: $color-footer-paragraph;
        }
        p {
            width: 80%;
            margin: 1em auto;
            font-weight: 300;
            color: $color-footer-paragraph;
        }
        a {
            text-decoration: none;
            color: $color-link-page;
        }
    }
    .footer {
        background-color: $color-brand-white;
    }
    &__content {
        padding: 5.313em 5.625em;
        background-color: $color-brand-white;
        box-shadow: 0 0 1px 0 rgba(27, 102, 164, 0.15);
        +below($small) {
            padding: 3rem;
        }
        +below($iphone6p) {
            padding: 1rem;
        }
        img {
            margin-right: 2.625em;
            +below($iphone6p) {
                margin-left: 1rem;
                height: auto;
            }
        }
        p {
            margin: 0 0 4em 0;
            font-size: 1.125em;
            line-height: 1.3em;
            color: $color-page-title;
            &:last-child {
                margin: 0;
            }
        }
        h2 {
            margin: 0 0 1.2em 0;
            color: $color-page-title;
        }
    }
}
.inbound {
    background-repeat: no-repeat;
    background-color: $color-background-inbound;
    background-position: 50% -10%;
    background-size: rem(1920);
    padding-bottom: 1px;

    +below($medium) {
        background-position: 50% -5%;
    }

    +below($small) {
        min-height: 5em;
        background-position: 50% 0;
        background-size: rem(1000);
    }

    .header {
        min-height: 7.5em;
        background-color: $color-brand-white;
    }

    &__telephone {

        h2 {
            font-size: 1.5rem;
        }
    }
    &__center {
        background-size: rem(1920);
        background-position: 50% -10%;


        h2 {
            font-size: 1.5rem;
        }

        +below($medium) {
            background-position: 50% -5%;
        }

        +below($tablet) {
            background-position: 50% 0;
            background-size: rem(1000);
        }

        +below($small) {
            background-position: 50% 0;
            background-size: rem(1000);
        }

        .inbound__section .container {
            +below($medium) {
                padding: 3.5rem;
            }
            +below($iphone6p) {
                margin-bottom: 2rem;
            }
        }
    }
    &__company {
        background-color: $color-background-press;
        background-position: 50% 0;

        +below($small) {
            background-position: 51% 0;
            background-size: 1000px;
        }
        h2 {
            font-size: 1.75rem;
        }
        .container {
            +below($tablet) {
                padding: 2rem !important;
            }
        }
    }
    &__album {
        padding: 2em 0;
        background-color: $color-background-job;

        +below($medium) {
            padding: 0;
        }

        .container {
            position: relative;
            padding: 0.625em 6.875em;
            color: $color-main-inbound;
            .icon {
                position: absolute;
                top: 55%;
                &__arrow-left {
                    left: 10px;
                }
                &__arrow-right {
                    right: 10px;
                }
            }
        }
        .album__section {
            col(1/3, cycle: 3);
            background-color: $color-brand-white;
            margin-bottom: rem(20);

            a {
                color: currentColor;
                text-decoration: none;
                outline: 0;
            }

            img {
                width: 100%;
                height: auto;
            }
            p {
                padding: 0 1.5em;
            }
        }
        .all-albums {
            cf();
            margin-top: rem(40);

            .album__section {
                +below($tablet) {
                    display: none;
                    &:first-child {
                        display: block;
                        text-align: center;
                        width: 100%;
                        background-color: $color-brand-white;
                    }
                }

            }
        }
    }
    &__contact {
        background-image: url('../images/placeholders/contact.jpg');
        background-color: $color-background-press;
        background-position: 50% 0;
    }
    &__office {
        background-color: $color-background-press;
        background-position: 50% 0;

        +below($iphone6p) {
            background-position: 0 100px;

        }
        .title {
            margin: 1.4em 0 !important;
        }
        .office__cont {
            h2 {
                margin-left: -3.5rem;
                +below($small) {
                    margin-left: 0;
                }
                .icon {
                    margin-right: 0.8rem;
                }
            }
        }
        .inbound__section {
            .container {
                padding: 5.625em 10.875em 4.5em 6.875em;
                +below($small) {
                    padding: 2.625em 1.875em 1.5em 1.875em;
                }
                p {
                    line-height: 22px;
                }
            }
        }
    }
    &__quality {
        background-color: $color-background-press;
        background-position: 50% 0;

        .title {
            margin: 1.4em 0 !important;
        }
        .inbound__section .container {
            +below($small) {
                padding: 3.625em 2.875em 2.5em;
                margin-bottom: 3rem;
            }
        }
    }
    &__listing {
        background-color: $color-background-press;
        background-position: 50% 0;
        .title {
            margin: 1.4em 0 !important;
        }
        .container {
            margin-bottom: 0 !important;
        }
        .inbound__section .container {
            +below($small) {
                padding: 4.625em 1.875em 2.5em;
            }

        }
        .listing__half {
            +below($medium) {
                width: 100%;
                margin-right: 0;
            }
            &--container:after {
                width: 100%;
            }
        }
        .listing__job {
            &__content {
                width: 70%;

                +below($medium) {
                    width: 66%;
                }
                +below($iphone6p) {
                    width: 58%;
                }
            }
            .listing-job {
                +below($large) {
                    width: 26%;
                }
                +below($medium) {
                    width: 32%;
                }
                +below($iphone6p) {
                    width: 42%;
                }
            }

        }
        .bottom__job {
            padding: 2rem 0;
            background-image: url('../images/backgrounds/job-bottom.png');
            background-position: 50% 0;
            background-repeat: no-repeat;
            text-align: center;
            h2 {
                font-size: 1.75rem;
                text-align: center;
                color: $color-menu-active;
                margin-top: 0;
                margin-bottom: 2.2rem;
            }
            p {
                margin-top: 1.5rem;
                color: $color-menu-active;
                a {
                    font-weight: bold;
                    text-decoration: none;
                    color: $color-menu-active;
                }
            }
        }
    }
    &__milestone {
        &.inbound__section .container {
            margin-bottom: 0;
        }
        .milestones {
            margin: 0 rem(-20);

            .slick-prev {
                left: rem(-45);
            }

            .slick-next {
                right: rem(-45);
            }
            .slick-dots {
                bottom: rem(-36);
            }

            &__item {
                margin-right: rem(20);
                margin-left: rem(20);

                a {
                    color: currentColor;
                    text-decoration: none;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .press__post {
            +below($medium) {
                text-align: left;
            }
            &--title {
                width: 70%;
                float: left;
                font-size: 1rem;
                margin-bottom: 0.4rem;

                a {
                    color: currentColor;
                    text-decoration: none;
                }
            }
            &--date {
                width: 30%;
                float: right;
                font-size: 0.875rem;
                text-align: right;
            }
            &--text {
                float: left;
                margin: 0;
                font-weight: 100;
                font-size: 1rem;
                color: $color-grey;
            }
        }

    }
    &__team {
        background-color: $color-background-news;
        .container {
            padding: 2.2em 6.875em;
            margin-bottom: 0;
            color: $color-main-inbound;
        }
    }
    &__virtual {
        background-image: url('../images/backgrounds/virtual.png');
        background-repeat: no-repeat;
        background-color: $color-background-press;
        padding-bottom: 1px;
        .container {
            padding: 2.2em 6.875em;
            margin-bottom: 2.5em;
            color: $color-main-inbound;
        }
        .video_container {
            margin-top: rem(35);
            text-align: center;

            overflow: hidden;
            position: relative;
            right: 10%;
            left: 10%;
            width: 80%;
            height: 0;
            padding-bottom: 45%;

            +below($tablet) {
                right: 0;
                left: 0;
                width: 100%;
                padding-bottom: 56%;
            }

            iframe,
            object, // @stylint ignore
            embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__section {
        .container {
            position: relative;
            padding: 5.625em 6.875em 4.5em 6.875em;
            margin-bottom: 5em;
            background-color: $color-brand-white;
            color: $color-main-inbound;
            &__inbound {
                float: left;
                margin-bottom: 4.3em;
                p {
                    line-height: 20px;
                }
                ul {
                    padding-left: 18px;

                    > li {
                        list-style: none;

                        &::before {
                            /* For a round bullet */
                            content: "\2022";
                            display: block;
                            position: relative;
                            max-width: 0;
                            max-height: 0;
                            left: rem(-18);
                            top: 0;
                            color: $color-background-news;
                            font-size: rem(20);
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        h2 {

            margin-top: 0;
        }
    }
    .title {
        margin: 5rem 0;
        font-size: 3.75rem;
        font-weight: 400;
        text-align: center;
        color: $color-brand-white;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);

        +below($tablet) {
            font-size: 2.5rem;
            margin: 2rem 0;
        }
        +below($iphone6p) {
            font-size: 2rem;
            margin: 2rem 0;
        }
    }
    .left_image {
        col(0.34);
        +below($desktop) {
            width: 100%;
        }
        img {
            width: 100%;
            +below($iphone6p) {
                max-width: 100%;
                height: auto;
            }
        }
    }
    .right_text {
        col(0.66);
        +below($desktop) {
            width: 100%;
            margin-top: 1rem;
        }
    }
    &__jobs {
        background-color: $color-background-press;
        background-position: 50% 0;

        .subtitle {
            text-align: center;
            font-size: 18px;
            color: $color-brand-white;
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);

            a {
                color: currentColor;
                text-decoration: none;
            }
        }
        .title {
            font-size: 6rem;
            margin-bottom: 3rem;
        }
        .jobs {
            .pagination {
                margin-top: rem(-10);
                margin-bottom: rem(40);
            }

            &__container {
                col(1/2, cycle: 2);
                background-color: $color-brand-white;
                padding: 3rem 2rem 3rem 2.9rem;
                box-shadow: 0 0 1px 0 rgba(27, 102, 164, 0.15);
                margin-bottom: 1.2rem;
                +below($xlarge) {
                    padding: 3rem 1rem 3rem 2rem;
                }
                +below($desktop) {
                    col(1, uncycle: 2);
                    padding: 3rem 2rem 3rem 2rem;
                    width: 80%;
                    float: none !important;
                    margin: 1rem auto !important;
                }
                +below($small) {
                    width: 100%;
                }
                h2 {
                    margin-top: 0;
                    color: $color-big-paragraph;
                    font-size: 1.5rem;
                    min-height: 3.5rem;

                    a {
                        color: currentColor;
                        text-decoration: none;
                    }
                }
                p {
                    color: $color-grey-alt;
                    font-size: 15px;
                    +below($tablet) {
                        width: 100% !important;
                        display: block;
                    }
                }
                .listing-job {
                    font-weight: 700;
                    width: 34%;
                    +below($small) {
                        width: 34%;
                    }
                    +below($xsmall) {
                        width: 38%;
                    }
                    +below($iphone6p) {
                        width: 42%;
                    }
                    .icon {
                        margin-right: 8px;
                    }
                }
                .job__open p {
                    +below($tablet) {
                        padding-bottom: 20px;
                    }
                }
            }
        }
        .listing__half--container {
            &:after {
                display: none;
            }
        }
        .job-bottom {
            cf();
            width: 100%;
            margin-top: 2.2rem;
            float: left;
            +below($small) {
                text-align: center;
            }
            +below($xsmall) {
                margin-top: 0;
            }

            p {
                float: right;
                margin-top: rem(5);
                margin-bottom: 0;

                +below($tablet) {
                    text-align: right;
                    width: 50%;
                }
                +below($small) {
                    width: 100%;
                    text-align: center;
                }
                .icon {
                    margin-right: 0.6rem;
                }
            }

            .job__open {
                &:hover {
                    color: $color-big-paragraph;
                }
            }

            .button {
                color: $color-big-paragraph;
                min-width: 9.2em;
                width: 9.2em;
                border: 2px solid $color-big-paragraph;
            }
        }
    }
}
.contacts {
    margin-bottom: 1.7rem;
    h2 {
        padding-left: 2.6rem;
        color: $color-menu-active;
        +below($small) {
            padding-left: 0;
        }
    }
    &__main {
        col(1/2);
        background-color: $color-brand-white;
        padding: 1rem 2.2rem 1rem 1rem;

        +below($desktop) {
            width: 65%;
            margin: 1rem auto !important;
            display: block;
            float: none;
        }
        +below($medium) {
            width: 100%;
        }
        +below($iphone6p) {
            padding: 1rem;
        }
        img {
            +below($medium) {
                max-width: 100%;
                height: auto;
            }
        }
        .contacts {
            &__phone {
                col(0.7);
                float: left;
                margin-top: 0.7rem;
                +below($medium) {
                    col(0.5);
                }
                +below($small) {
                    width: 62%;
                }
                h3 {
                    margin-bottom: 0.5rem;
                    font-size: 1.2rem;
                    color: $color-menu-active;
                    text-transform: uppercase;
                }
                &--con {
                    col(1/2);
                    p {
                        font-size: 1rem;
                        margin-bottom: 0;
                        margin-top: 0.4rem;
                        color: $color-grey-alt;
                        font-weight: 100;
                    }
                    a {
                        color: $color-link-page;
                        text-decoration: none;
                    }
                    +below($medium) {
                        width: 100%;
                    }
                }
            }
            &__image {
                col(0.3);
                float: left;
                padding: 1.5em;
                text-align: center !important;

                +below($medium) {
                    col(0.5);
                }

                +below($small) {
                    width: 30%;
                    padding: 2rem 0;
                    margin-right: 8%;

                }
                img {
                    width: rem(94);
                    max-width: none;
                    height: auto;
                }
            }

        }
    }
    .container {
        +below($desktop) {
            padding: 0 2rem;
        }
        +below($small) {
            padding: 0 rem(10);
        }
    }
    &__offices {
        col(1/3, cycle: 3);
        padding: 2.62rem;
        background-color: $color-brand-white;
        border: 1px solid $color-background-call;
        margin-bottom: 1rem;
        +below($desktop) {
            col(1/2, uncycle: 3, cycle: 2);
        }
        +below($medium) {
            col(1, uncycle: 3, uncycle: 2);
        }
        +below($iphone6p) {
            padding: 1.5rem;
        }
        .contacts__image {
            col(0.4);
            text-align: center;
        }
        .contacts__phone {
            col(0.6);
            cf();
            h3 {
                margin-top: 0;
                margin-bottom: 7px;
                font-size: 1.1rem;
                color: $color-menu-active;
                text-transform: uppercase;
            }
            p {
                margin-top: 0;
                font-size: 0.8rem;
                color: $color-grey-alt;
                line-height: 1.4rem;
            }
        }
    }
}
.office {
    h2 {
        font-size: 1.5rem;
        color: $color-main-inbound;
    }
    p {
        font-size: 1rem;
        color: $color-main-inbound;
    }
    &__cont {
        margin-bottom: 4.5rem;
        &:last-child {
            margin:0;
        }
    }
}
.quality {
    &__image {
        col(1/3);
        padding: 10px;
        text-align: center;
        border: 1px solid $color-background-news;
        +below($medium) {
            width: 100%;
            margin-bottom: 1rem;
        }
        +below($small) {

        }
        img {
            +below($small) {
                max-width: 100%;
                height: auto;
            }
        }
    }
    &__text {
        col(2/3);
        margin-right: 0;
        &--full {
            float: left;
            width: 100%;
            margin-top: 2.2rem;
            +below($medium) {
                margin-top: 0;
            }
        }
        +below($medium) {
            width: 100%;
        }
    }
}

.job {
    background-image: url('../images/placeholders/apply-job.jpg');
    background-repeat: no-repeat;
}
.job.apply-job {
    background-image: url("../images/placeholders/apply-job.jpg");
    background-position: 50% 0;
}

.listing {
    h2 {
        font-size: 1.75rem;
    }
    &__halft {
        col(1/3);
        padding: 1.5rem 2.2rem;
        background-color: $color-background-job;
        .button {
            font-weight: 600;
        }
        h3 {
            margin-top: 0;
            font-size: 1.5rem;
            font-weight: 400;
        }
        +below($medium) {
            display: none;
        }
    }
    &__half {
        col(2/3);
        &--container {
            float: left;
            width: 100%;
            h2 {
                .icon {
                    margin-right: rem(5);
                    vertical-align: initial;
                }
            }
            &:after {
                content: "";
                display: inline-block;
                width: 70%;
                margin: 40px 0 30px 0;
                height: 1px;
                background-color: $color-background-news;
            }
            &:last-child:after {
                display: none;
            }
        }
    }
    &__job {
        width: 100%;
        float: left;
        margin-bottom: 0.8rem;
        .icon, p {
            float: left;
            margin-top: 0;
            margin-bottom: 0;
        }

        .icon {
            margin-right: 8px;
        }
        .listing-job {
            width: 20%;
            font-weight: 600;

        }
    }
    ul {
        padding-left: 18px;

        > li {
            list-style: none;
            margin: rem(10) 0;

            &::before {
                /* For a round bullet */
                content: "\2022";
                display: block;
                position: relative;
                max-width: 0;
                max-height: 0;
                left: rem(-18);
                top: -2px;
                color: $color-background-news;
                font-size: rem(20);
            }
        }
    }
}

.section {
    position: relative;
    padding: rem(20);
    &__page {
        &--title {
            width: 85%;
            margin: 0 auto;
        }
        .container {
            margin: 1.4em auto;
            +below($desktop) {
                margin: 0 auto;
            }
        }
        .pagination {
            width: 10.313em;
            margin: 0 auto;
            margin-bottom: 1rem;
            margin-top: 2.5rem;
            text-align: center;
            cf();

            ul {
                list-style: none;
                li {
                    display: inline-block;
                    float: left;
                    width: 33px;
                    height: 33px;
                    border-top: 1px solid $color-background-job;
                    border-bottom: 1px solid $color-background-job;
                    border-left: 1px solid $color-background-job;
                    background-color: $color-brand-white;
                    color: $color-big-paragraph;
                    font-size: $p-small-font-size;
                    line-height: 1;
                    span {
                        display: block;
                        cursor: not-allowed;
                        padding-top: 9px;
                    }
                    a {
                        display: block;
                        padding: 9px;
                        color: $color-big-paragraph;
                        text-decoration: none;
                    }

                    &:hover {
                        color: $color-brand-white;
                        background-color: $color-big-paragraph;
                        a {
                            color: $color-brand-white;
                        }
                    }

                    &:first-child {
                        border-left: 1px solid $color-background-job;
                        border-bottom: 1px solid $color-background-job;
                        border-radius: 3px 0 0 3px;
                    }
                    &:last-child {
                        border-top: 1px solid $color-background-job;
                        border-right: 1px solid $color-background-job;
                        border-radius: 0 3px 3px 0;
                    }
                }
            }
            &__block {
                display: inline-block;
                float: left;
                width: 33px;
                height: 33px;
                padding: 9px;
                border-top: 1px solid $color-background-job;
                border-bottom: 1px solid $color-background-job;
                border-left: 1px solid $color-background-job;
                background-color: $color-brand-white;
                font-size: $p-small-font-size;
                line-height: 1;
                color: $color-big-paragraph;
                text-decoration: none;

                &:hover {
                    color: $color-brand-white;
                    background-color: $color-big-paragraph;
                }

                &:first-child {
                    border-left: 1px solid $color-background-job;
                    border-bottom: 1px solid $color-background-job;
                    border-radius: 3px 0 0 3px;
                }
                &:last-child {
                    border-top: 1px solid $color-background-job;
                    border-right: 1px solid $color-background-job;
                    border-radius: 0 3px 3px 0;
                }
            }

            .prev:hover {
                &::after {
                    border-right: 6px solid white;
                }
            }
            .next:hover {
                &::after {
                    border-left: 6px solid white;
                }
            }

            .prev:after {
                content: "";
                position: relative;
                top: -2px;
                right: 1px;
                display: inline-block;
                width: 0;
                height: 0;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-right: 6px solid $color-bright-grey;
            }
            .next:after {
                content: "";
                position: relative;
                top: -2px;
                left: 1px;
                display: inline-block;
                width: 0;
                height: 0;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 6px solid $color-bright-grey;
            }
            &__active {
                color: $color-bright-grey;
                &:hover {
                    color: $color-brand-white;
                }
            }
        }
    }

// Backgrounds
    &.-bright {
        color: black;
        background-color: white;
    }
    &.-dark {
        background-color: black;
        color: white;
    }
    &.-align-center { text-align: center; }

// Font sizes
    &.-font-size {
        &-medium {
            font-size: rem(21);
            line-height: 1.7;
        }
        &-normal {
            font-size: rem(19);
            line-height: 1.9;
        }
        &-small {
            font-size: rem(17);
            line-height: 1.7;
        }
    }
// Helpers
    &.-no-padding { padding: 0; }
    &.-half-padding { padding: 0; }

// Headings
    h1 {
        font-family: $sans-font-family;
        line-height: $h-large-line-height;
    }
    h2 {
        font-family: $sans-font-family;
    }
    h3 {
        font-family: $sans-font-family;
        line-height: $h-normal-line-height;
    }
    h4 {
        font-family: $h-small-font-family;
        font-size: $h-small-font-size;
        font-weight: $h-small-font-weight;
        line-height: $h-small-line-height;
    }
    .heading {
        &.-size-large {
            @extends .section h1; // @stylint ignore
        }
        &.-size-big {
            font-size: rem(72);

            +below($small) {
                font-size: rem(60);
            }
        }
        &.-size-normal {
            @extends .section h2; // @stylint ignore
        }
        &.-size-medium {
            @extends .section h3; // @stylint ignore
        }
        &.-size-small {
            @extends .section h4; // @stylint ignore
        }
        &.-align-center {
            text-align: center;
        }
        &.-no-margin-bottom {
            margin-bottom: 0;
        }
        &.-no-margin-top {
            margin-top: 0;
        }
        &.-color-black {
            color: $color-brand-black;
        }

        &.-color-white {
            color: white;
        }

        a {
            color: currentColor; // @stylint ignore
            text-decoration: none;
            &:hover { color: currentColor; } // @stylint ignore
        }
    }

// Images
    .image {
        width: 100%;
        height: auto;

        &.-size-half {
            display: block;
            margin: 0 auto;
            width: 50%;
        }
    }

// Other typography
    em, .emphasis {
        color: $color-brand-black;
    }

    hr, .separator {
        height: 0;
        margin: rem(50) auto;
        border-style: solid;
        border-color: $color-brand-white;
        border-width: 0 0 2px 0;

        &.-color-black { border-color: black; }
        &.-no-margin { margin: 0 auto; }
    }
    u, .underline { //@stylint ignore
        position: relative;
        text-decoration: none;
        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            border-bottom: 1px solid currentColor;
        }
    }

// Footer
    .footer { }

// Columns
    .columns {
        position: relative;
        cf();

        &__six {
            col(1/2, cycle: 2);

            +below($medium) {
                col(1/2, cycle: 2);
            }

            +below($tablet) {
                col(1, uncycle: 2);
            }
        }
    }
}

// 404
.page-404 {
    &.main {
        background-position: 50% 0;
        background-size: cover;
        background-repeat: none;
    }

    .section {
        padding-bottom: rem(140);

        +below($small) {
            padding-bottom: rem(80);
        }
    }

    .page__title {
        margin-bottom: rem(30);
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);

        +below($xsmall) {
            font-size: rem(50);
        }
    }

    p {
        margin-right: auto;
        margin-left: auto;
        max-width: rem(750);
        font-size: rem(18);
        line-height: 1.5;
        color: white;
    }

    .button {
        display: inline-block;
        margin-top: rem(30);
    }
}
.main {
    background-repeat: no-repeat;
    background-position: 50% 0;
}
